<template>
  <v-container class="align-center justify-center">
    <v-card
        elevation="9"
        outlined
        shaped
        tile
        :loading="loading"
        max-width="800"
        color="amber accent-1"
        class="justify-center"
    >
      <v-card-title class="align-center justify-center">{{ title }}</v-card-title>
      <v-card-text>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
          <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
            <v-card class="light-blue lighten-3">
              <v-card-text>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                        rules=""
                                        v-slot="{ errors }">
                      <ul v-for="err in errors" :key="err">
                        <li>
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider mode="passive" vid="branch" name="Branch name"
                                        rules="objectNotEmpty:code|required"
                                        v-slot="{ errors }">
                      <v-select :label="office_selection_label + '*'" v-model="report_data.office"
                                hint="The office for which you want to generate report"
                                :items="branch" item-text="name" clearable
                                item-value="id" item-color="primary" :loading="branch_loading">
                        <template v-slot:prepend-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-text-field v-model="search" placeholder="Search"
                                            @input="searchBranch"></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>


                      </v-select>
                      <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider mode="passive" vid="report_name" name="Report Name"
                                        rules="objectNotEmpty:value|required"
                                        v-slot="{ errors }">
                      <v-select label="Select a Report*" v-model="report_data.report_name"
                                hint="The branch for which you want to generate report"
                                :items="report_name" item-text="name" clearable
                                item-value="value" item-color="primary" :loading="report_name_loading">

                      </v-select>
                      <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider mode="passive" vid="file_format" name="File Format"
                                        rules="objectNotEmpty:value|required"
                                        v-slot="{ errors }">
                      <v-select label="Select a Report file format*" v-model="report_data.file_format"
                                hint="The format in which you want to generate report"
                                :items="file_format" item-text="text" clearable
                                item-value="value" item-color="primary">

                      </v-select>
                      <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-5"
                    text
                    type="submit"
                    :loading="loading"
                    elevation="9"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
    <report_viewer :dialog_visibility="report_visibility" :hex-pdf="hexPdf" :report_file_name="report_file_name"
                   @close_event="closeDialog">
    </report_viewer>
  </v-container>
</template>

<style scoped>

</style>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import axios from "axios";
import "@/validations/office_validations"
import report_viewer from "@/components/report/report_viewer.vue";
import parsing_functions from "@/constants/util_functions/parsing_functions";

export default {
  name: "branch_day_wise_form",
  props: ['title', 'office_url', 'report_name_url', 'submit_url', 'file_format', 'office_selection_label'],
  components: {
    ValidationObserver,
    ValidationProvider,
    report_viewer,
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      axios.defaults.headers.common['multi'] = true
      return 0
    },
  },
  data: () => ({
    loading: false,
    report_data: {
      office: null,
      from_date: null,
      to_date: null,
      report_name: null,
      file_format: null,
    },
    search: null,
    branch: [],
    branchCopy: [],
    report_name: [],
    branch_loading: false,
    report_file_name: null,
    report_name_loading: false,
    from_date_menu: false,
    to_date_menu: false,
    report_visibility: false,
    hexPdf: null
  }),
  methods: {
    handle_submit() {
      this.axios_conf
      this.loading = true
      axios.post(this.submit_url, this.report_data)
          .then((resp) => {
            this.$refs.vform.resetValidation()
            this.hexPdf = resp.data.report
            this.$store.commit('setJWT', resp.data.key)

            if (this.report_data.file_format === 'pdf') {
              for (const item of this.report_name) {
                if (item.value === this.report_data.report_name) {
                  this.report_file_name = item.name
                  break
                }
              }
              this.report_visibility = true
            } else {
              this.download_report(this.hexPdf)
            }
            this.loading = false
          })
          .catch((err) => {
            if (err.response.data) {
              this.$store.commit('setJWT', err.response.data.key)
              this.$refs.observer.setErrors({
                branch: err.response.data.errors.branch,
                report_name: err.response.data.errors.report_name,
                from_date: err.response.data.errors.from_date,
                to_date: err.response.data.errors.to_date,
                non_field: err.response.data.errors.non_field,
              });
            }
          })

    },
    get_branches() {
      this.axios_conf
      this.branch_loading = true
      axios.get(this.office_url)
          .then((resp) => {
            this.branch = resp.data.branches || resp.data.zones || resp.data.circles
            this.branchCopy = [...this.branch]
            this.$store.commit('setJWT', resp.data.key)
            this.branch_loading = false
          })
    },
    get_report_name_array() {
      this.axios_conf
      this.report_name_loading = true
      axios.get(this.report_name_url)
          .then((resp) => {
            this.report_name = resp.data.data
            this.$store.commit('setJWT', resp.data.key)
            this.report_name_loading = false
          })
    },
    closeDialog(value) {
      this.report_visibility = value
    },
    download_report(hexstr) {
      let blob = parsing_functions.hex2blob(hexstr, 'application/vnd.ms-excel')
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      let report_title = 'Report'
      for (const item of this.report_name) {
        if (item.value === this.report_data.report_name) {
          report_title = item.name
          break
        }
      }
      link.download = report_title + ".xls"
      link.click()
      URL.revokeObjectURL(link.href)
    },
    searchBranch() {
      if (!this.search) {
        this.branch = this.branchCopy;
      }
      this.branch = this.branchCopy.filter((br) => {
        return br.id.toString() === this.search || br.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  mounted() {
    this.get_branches()
    this.get_report_name_array()
  }
}

</script>