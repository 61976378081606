const report_urls = {
    topsheet: 'report/topsheet/{file_id}/',
    get_report_name: 'report/{report_slug}/',
    get_branch_day_wise_report: 'report/branch/day_wise/',
    get_branch_non_day_wise_report: 'report/branch/non_day_wise/',
    get_zone_day_wise_report: 'report/zone/day_wise/',
    get_zone_non_day_wise_report: 'report/zone/non_day_wise/',
    get_circle_day_wise_report: 'report/circle/day_wise/',
    get_circle_non_day_wise_report: 'report/circle/non_day_wise/',

    get_head_office_day_wise_report: 'report/head_office/day_wise/',
    get_head_office_non_day_wise_report: 'report/head_office/non_day_wise/',

    get_own_branch_day_wise_report: 'report/own_branch/day_wise/',
    get_own_branch_non_day_wise_report: 'report/own_branch/non_day_wise/',

    get_own_zone_day_wise_report: 'report/own_zone/day_wise/',
    get_own_zone_non_day_wise_report: 'report/own_zone/non_day_wise/',

    get_own_circle_day_wise_report: 'report/own_circle/day_wise/',
    get_own_circle_non_day_wise_report: 'report/own_circle/non_day_wise/',
};

export default report_urls;
