<template>
  <v-container class="justify-center align-center">
    <form_container class="justify-center align-center"></form_container>
  </v-container>
</template>

<script>

import form_container from "@/components/report/form_container.vue";

export default {
  name: "ReportView",
  components: {
    form_container
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Reports'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>