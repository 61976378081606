var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"align-center justify-center"},[_c('v-card',{staticClass:"justify-center",attrs:{"elevation":"9","outlined":"","shaped":"","tile":"","loading":_vm.loading,"max-width":"800","color":"amber accent-1"}},[_c('v-card-title',{staticClass:"align-center justify-center"},[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"vform",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handle_submit)}}},[_c('v-card',{staticClass:"light-blue lighten-3"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"non_field","name":"None Field","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((errors),function(err){return _c('ul',{key:err},[_c('li',[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])])})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"branch","name":"Branch name","rules":"objectNotEmpty:code|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.office_selection_label + '*',"hint":"The office for which you want to generate report","items":_vm.branch,"item-text":"name","clearable":"","item-value":"id","item-color":"primary","loading":_vm.branch_loading},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"placeholder":"Search"},on:{"input":_vm.searchBranch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}],null,true),model:{value:(_vm.report_data.office),callback:function ($$v) {_vm.$set(_vm.report_data, "office", $$v)},expression:"report_data.office"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"report_name","name":"Report Name","rules":"objectNotEmpty:value|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Select a Report*","hint":"The branch for which you want to generate report","items":_vm.report_name,"item-text":"name","clearable":"","item-value":"value","item-color":"primary","loading":_vm.report_name_loading},model:{value:(_vm.report_data.report_name),callback:function ($$v) {_vm.$set(_vm.report_data, "report_name", $$v)},expression:"report_data.report_name"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"file_format","name":"File Format","rules":"objectNotEmpty:value|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Select a Report file format*","hint":"The format in which you want to generate report","items":_vm.file_format,"item-text":"text","clearable":"","item-value":"value","item-color":"primary"},model:{value:(_vm.report_data.file_format),callback:function ($$v) {_vm.$set(_vm.report_data, "file_format", $$v)},expression:"report_data.file_format"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-5","text":"","type":"submit","loading":_vm.loading,"elevation":"9"}},[_vm._v(" Submit ")])],1)],1)],1)]}}])})],1)],1),_c('report_viewer',{attrs:{"dialog_visibility":_vm.report_visibility,"hex-pdf":_vm.hexPdf,"report_file_name":_vm.report_file_name},on:{"close_event":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }