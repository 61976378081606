<template>
  <v-dialog
      v-model="dialog_visibility"
      scrollable
      max-width="90%"
      max-height="90%"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ report_file_name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <object :data="pdfBlob" type="application/pdf" width="100%" height="600px">
          Your browser does not support embedded PDFs.
        </object>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<style scoped>

</style>
<script>
import parsing_functions from "@/constants/util_functions/parsing_functions";

export default {
  name: "report_viewer",
  props: ['dialog_visibility', 'hexPdf', 'report_file_name'],
  computed: {
    pdfBlob() {
      let pdf_blob = parsing_functions.hex2blob(this.hexPdf, 'application/pdf')
      return URL.createObjectURL(pdf_blob);
    }
  },
  data: () => ({}),
  methods: {
    closeDialog() {
      this.$emit('close_event', false)
    }
  },
}

</script>