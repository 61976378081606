<template>
  <v-container class="justify-center align-center">
    <!--branch office-->
    <branch_day_wise_form v-if="all_branch_day_wise_form"
                          title="All Branch Day Wise Reports"
                          :report_name_url="report_url.get_report_name.replace('{report_slug}','branch_day_wise')"
                          :file_format="file_format"
                          :office_url="office_urls.branch.list"
                          :submit_url="report_url.get_branch_day_wise_report" office_selection_label="Select a Branch"
                          key="all_branch_day_wise_form">

    </branch_day_wise_form>
    <branch_non_day_wise_form v-if="all_branch_non_day_wise_form" title="All Branch Reports"
                              :report_name_url="report_url.get_report_name.replace('{report_slug}','branch_non_day_wise')"
                              :file_format="file_format"
                              :office_url="office_urls.branch.list"
                              :submit_url="report_url.get_branch_non_day_wise_report"
                              office_selection_label="Select a branch" key="all_branch_non_day_wise_form">

    </branch_non_day_wise_form>
    <!--    zonal office-->
    <branch_day_wise_form v-if="all_zone_day_wise_form" title="All Zone Day Wise Reports"
                          :report_name_url="report_url.get_report_name.replace('{report_slug}','zone_day_wise')"
                          :file_format="file_format"
                          :office_url="office_urls.zone.list"
                          :submit_url="report_url.get_zone_day_wise_report"
                          office_selection_label="Select a zone" key="all_zone_day_wise_form">
    </branch_day_wise_form>
    <branch_non_day_wise_form v-if="all_zone_non_day_wise_form" title="All Zone Reports"
                              :report_name_url="report_url.get_report_name.replace('{report_slug}','zone_non_day_wise')"
                              :file_format="file_format"
                              :office_url="office_urls.zone.list"
                              :submit_url="report_url.get_zone_non_day_wise_report"
                              office_selection_label="Select a zone" key="all_zone_non_day_wise_form">

    </branch_non_day_wise_form>

    <!--    circle office-->
    <branch_day_wise_form v-if="all_circle_day_wise_form" title="All Circle Day Wise Reports"
                          :report_name_url="report_url.get_report_name.replace('{report_slug}','circle_day_wise')"
                          :file_format="file_format"
                          :office_url="office_urls.circle.list"
                          :submit_url="report_url.get_circle_day_wise_report"
                          office_selection_label="Select a circle" key="all_circle_day_wise_form">
    </branch_day_wise_form>
    <branch_non_day_wise_form v-if="all_circle_non_day_wise_form" title="All Circle Reports"
                              :report_name_url="report_url.get_report_name.replace('{report_slug}','circle_non_day_wise')"
                              :file_format="file_format"
                              :office_url="office_urls.circle.list"
                              :submit_url="report_url.get_circle_non_day_wise_report"
                              office_selection_label="Select a circle" key="all_circle_non_day_wise_form">

    </branch_non_day_wise_form>

    <!--    head office-->
    <single_office_day_wise_form v-if="head_office_day_wise_form" title="Head Office Day Wise Reports"
                                 :report_name_url="report_url.get_report_name.replace('{report_slug}','head_office_day_wise')"
                                 :file_format="file_format"
                                 :submit_url="report_url.get_head_office_day_wise_report"
                                 key="head_office_day_wise_form">
    </single_office_day_wise_form>
    <single_office_non_day_wise_form v-if="head_office_non_day_wise_form" title="Head Office Non Day Wise Reports"
                                     :report_name_url="report_url.get_report_name.replace('{report_slug}','head_office_non_day_wise')"
                                     :file_format="file_format"
                                     :submit_url="report_url.get_head_office_non_day_wise_report"
                                     key="head_office_non_day_wise_form">
    </single_office_non_day_wise_form>

    <!--    own branch-->
    <single_office_day_wise_form v-if="own_branch_day_wise_form" title="Own Branch Day Wise Reports"
                                 :report_name_url="report_url.get_report_name.replace('{report_slug}','branch_day_wise')"
                                 :file_format="file_format"
                                 :office_value="this.$store.state.branch_data.id"
                                 :submit_url="report_url.get_own_branch_day_wise_report"
                                 key="own_branch_day_wise_form">
    </single_office_day_wise_form>
    <single_office_non_day_wise_form v-if="own_branch_non_day_wise_form" title="Own branch Non Day Wise Reports"
                                     :report_name_url="report_url.get_report_name.replace('{report_slug}','branch_non_day_wise')"
                                     :file_format="file_format"
                                     :office_value="this.$store.state.branch_data.id"
                                     :submit_url="report_url.get_own_branch_non_day_wise_report"
                                     key="own_branch_non_day_wise_form">
    </single_office_non_day_wise_form>

    <!--    zone office-->
    <single_office_day_wise_form v-if="own_zone_day_wise_form" title="Own Zone Day Wise Reports"
                                 :report_name_url="report_url.get_report_name.replace('{report_slug}','zone_day_wise')"
                                 :file_format="file_format"
                                 :office_value="this.$store.state.branch_data.zone_id"
                                 :submit_url="report_url.get_own_zone_day_wise_report"
                                 key="own_zone_day_wise_form">
    </single_office_day_wise_form>
    <single_office_non_day_wise_form v-if="own_zone_non_day_wise_form" title="Own Zone Non Day Wise Reports"
                                     :report_name_url="report_url.get_report_name.replace('{report_slug}','zone_non_day_wise')"
                                     :file_format="file_format"
                                     :office_value="this.$store.state.branch_data.zone_id"
                                     :submit_url="report_url.get_own_zone_non_day_wise_report"
                                     key="own_zone_non_day_wise_form">
    </single_office_non_day_wise_form>

    <!--    circle office-->
    <single_office_day_wise_form v-if="own_circle_day_wise_form" title="Own Circle Day Wise Reports"
                                 :report_name_url="report_url.get_report_name.replace('{report_slug}','circle_day_wise')"
                                 :file_format="file_format"
                                 :office_value="this.$store.state.branch_data.circle_id"
                                 :submit_url="report_url.get_own_circle_day_wise_report"
                                 key="own_circle_day_wise_form">
    </single_office_day_wise_form>
    <single_office_non_day_wise_form v-if="own_circle_non_day_wise_form" title="Own Circle Non Day Wise Reports"
                                     :report_name_url="report_url.get_report_name.replace('{report_slug}','circle_non_day_wise')"
                                     :file_format="file_format"
                                     :office_value="this.$store.state.branch_data.circle_id"
                                     :submit_url="report_url.get_own_circle_non_day_wise_report"
                                     key="own_circle_non_day_wise_form">
    </single_office_non_day_wise_form>
  </v-container>
</template>

<style scoped>
</style>

<script>
import branch_day_wise_form from "@/components/report/branch_day_wise_form.vue";
import branch_non_day_wise_form from "@/components/report/branch_non_day_wise_form.vue";
import single_office_day_wise_form from "@/components/report/single_office_day_wise_form.vue";
import single_office_non_day_wise_form from "@/components/report/single_office_non_day_wise_form.vue";
import report_perms from "@/constants/permissions/report_permissions";
import report_urls from "@/constants/reports/report";
import {user_urls} from "@/constants/user_urls";

export default {
  name: "form_container",
  components: {
    branch_day_wise_form,
    branch_non_day_wise_form,
    single_office_day_wise_form,
    single_office_non_day_wise_form
  },
  computed: {
    all_branch_day_wise_form() {
      return this.$route.name === 'all_branch_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_branch_day_wise)
    },
    all_branch_non_day_wise_form() {
      return this.$route.name === 'all_branch_non_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_branch_non_day_wise)
    },
    all_zone_day_wise_form() {
      return this.$route.name === 'all_zone_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_zone_day_wise)
    },
    all_zone_non_day_wise_form() {
      return this.$route.name === 'all_zone_non_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_zone_non_day_wise)
    },
    all_circle_day_wise_form() {
      return this.$route.name === 'all_circle_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_circle_day_wise)
    },
    all_circle_non_day_wise_form() {
      return this.$route.name === 'all_circle_non_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_circle_non_day_wise)
    },
    head_office_day_wise_form() {
      return this.$route.name === 'head_office_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_head_office_day_wise)
    },
    head_office_non_day_wise_form() {
      return this.$route.name === 'head_office_non_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_head_office_non_day_wise)
    },
    own_branch_day_wise_form() {
      return this.$route.name === 'own_branch_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_own_branch_day_wise)
    },
    own_branch_non_day_wise_form() {
      return this.$route.name === 'own_branch_non_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_own_branch_non_day_wise)
    },
    own_zone_day_wise_form() {
      return this.$route.name === 'own_zone_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_own_zone_day_wise)
    },
    own_zone_non_day_wise_form() {
      return this.$route.name === 'own_zone_non_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_own_zone_non_day_wise)
    },
    own_circle_day_wise_form() {
      return this.$route.name === 'own_circle_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_own_circle_day_wise)
    },
    own_circle_non_day_wise_form() {
      return this.$route.name === 'own_circle_non_day_wise' && this.$store.getters.permissionCheckers(report_perms.view_report_own_circle_non_day_wise)
    },
    report_url() {
      return report_urls
    },
    office_urls() {
      return user_urls.office
    }
  },
  data: () => ({
    file_format: [
      {
        "text": "PDF",
        "value": 'pdf',
      },
      {
        "text": "Excel",
        "value": 'xls'
      }
    ],
  })
}

</script>